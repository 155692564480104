import { useState, useRef } from 'react';

import Text from '@material-ui/core/Typography';

import useStyles from './styles';
import { Box, IconButton } from '@mui/material';
import './style.css';

export default function AudioRecord4() {
    const classes = useStyles();
    const [isRecording, setIsRecording] = useState(false);
    const [showError, setShowError] = useState<any>('');

    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const playerRef = useRef<HTMLAudioElement>(null);
    const blobs = useRef<Blob[]>([]);

    const startRecording = async () => {
        setIsRecording(true);
        blobs.current.length = 0;

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log('mediaDevices supported..');
            setShowError('mediaDevices supported..');

            navigator.mediaDevices
                .getUserMedia({
                    audio: true,
                })
                .then((stream) => {
                    mediaRecorder.current = new MediaRecorder(stream);

                    mediaRecorder.current.ondataavailable = (e: any) => {
                        blobs.current.push(e.data);
                    };

                    mediaRecorder.current.onstop = showRecordedAudio;
                    // Let's receive 1 second blobs
                    mediaRecorder.current.start();
                })
                .catch((error) => {
                    setShowError(error);
                    console.log('Following error has occured : ', error);
                });
        } else {
            setShowError('mediaDevices not supported..');
        }
    };

    const stopRecording = () => {
        setIsRecording(false);
        // Let's stop capture and recording
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
        }
    };

    const showRecordedAudio = () => {
        console.log(blobs.current[0]);
        setShowError(blobs.current[0].type);
        if (!blobs.current.length) return;
        const mimeType = blobs.current ? blobs.current[0].type : '';
        const blob = new Blob(blobs.current, {
            type: mimeType,
        });

        // Show recorded audio
        if (playerRef.current) {
            const url = window.URL.createObjectURL(blob);
            console.log(url);
            setShowError((showError: any) => showError + url);
            playerRef.current.src = url;
        }
    };

    return (
        <>
            <Box className={classes.root}>
                <Text className={classes.ctaText}>TRY RECORDING ONE NOW!</Text>
                <audio className={classes.player} ref={playerRef} controls={true}></audio>
                <Text>{showError}</Text>
                {isRecording ? (
                    <IconButton className={classes.stopButton} onClick={stopRecording}>
                        stop record
                    </IconButton>
                ) : (
                    <IconButton className={classes.recordButton} onClick={startRecording}>
                        start record
                    </IconButton>
                )}
            </Box>
        </>
    );
}
